/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-15 16:43:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 16:36:02
 */
export const columns = [
  {
    title: '大区',
    dataIndex: 'mhiacAreaName',
    key: 'mhiacAreaName',
    width:100,
    align:'center'
  },{
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    width:100,
    align:'center'
  },{
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width:150,
    align:'center'
},
{
    title: '折扣名称',
    dataIndex: 'rebateName',
    key: 'rebateName',
    width:200,
    align:'center'
},
{
    title: '折扣金额',
    dataIndex: 'rebateAmount',
    key: 'rebateAmount',
    width:100,
    align:'center',
    scopedSlots: { customRender: 'rebateAmount' }
},
{
    title: '剩余金额',
    dataIndex: 'remainingAmount',
    key: 'remainingAmount',
    width:100,
    align:'center',
    scopedSlots: { customRender: 'remainingAmount' }
},
{
    title: '有效期',
    dataIndex: 'startTime',
    key: 'startTime',
    width:220,
    align:'center',
    scopedSlots: { customRender: 'startTime'}

},
  {
    title: '是否延期',
    dataIndex: 'flagApply',
    key: 'flagApply',
    width:100,
    align:'center',
    scopedSlots: { customRender: 'flagApply'}

  },
  {
    title: '初始结束时间',
    dataIndex: 'defaultEndTime',
    key: 'defaultEndTime',
    width:120,
    align:'center',
    scopedSlots: { customRender: 'defaultEndTime'}

  },
  {
    title: '延期次数',
    dataIndex: 'applyCount',
    key: 'applyCount',
    width:100,
    align:'center'
  },
  {
    title: '状态',
    dataIndex: 'usedStatus',
    key: 'usedStatus',
    width:150,
    align:'center',
    scopedSlots: { customRender: 'usedStatus'}
  },
  {
    title: '优惠费用类型',
    dataIndex: 'costType',
    key: 'costType',
    width:150,
    align:'center',
    scopedSlots: { customRender: 'costType'}
  },
  // {
  //   title: '状态',
  //   dataIndex: 'status',
  //   key: 'status',
  //   width:100,
  //   align:'center',
  //   scopedSlots: { customRender: 'status'}
  // },
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width:120,
    align:'center',
    ellipsis:true
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    width:100,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagEnable'}
},
{
  title: '适用范围',
  dataIndex: 'categoryNameStr',
  key: 'categoryNameStr',
  width:200,
  align:'left',
  ellipsis:true
},
  {
    title: '附件',
    dataIndex: 'rebateFilesNum',
    key: 'rebateFilesNum',
    width:100,
    align:'center',
    scopedSlots: { customRender: 'rebateFilesNum'}
  },

{
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width:150,
    align:'center',
    ellipsis:true
}
]
